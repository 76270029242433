<template lang="pug">
section#v-extra
  v-divider BONUS
  .banner: .container
    p
      | 想看更多 NVIDIA 虛實整合的數位雙生技術？
      br
      | 都在 COMPUTEX 2021 大展
    .link: a(href='https://www.nvidia.com/zh-tw/events/computex/', target='_blank')
      span 點我了解
      img(src='@/assets/icon-external.png', alt='外部連結')
</template>

<script>
import VDivider from '@/components/Divider.vue'

export default {
  components: {
    VDivider,
  },
}
</script>

<style lang="sass" scoped>
@import "~@/mixin.sass"

.banner
  +background(url(~@/assets/extra-bg.jpg))
  +padding-y(60px, 48px)
p
  +font(18px, white, 1.5, .05em, bold, center)
  margin-bottom: 28px
.link
  text-align: center
  a
    +iflex(center, center)
    +ifont(16px, white, null, .05em, bold, center)
    +size(140px, 44px)
    background-color: #74B71B
    border-radius: 100px
    &:hover
      text-decoration: none
    img
      width: 1.2em
      margin-left: .5em

@media (min-width: 1019px)
  p
    font-size: 24px
  .link
    a
      +size(200px, 60px)
      font-size: 24px
</style>
