<template lang="pug">
footer#v-footer: .container
  .logos
    .partners
      h3 合作夥伴
      ul
        li: a(href='https://www.nvidia.com/zh-tw/', target='_blank'): img(src='@/assets/logo-white-nvidia.png', alt='NVIDIA 香港商輝達香港控股有限公司台灣分公司')
        li: a(href='https://intelligenx.ai/', target='_blank'): img(src='@/assets/logo-white-kakix.png', alt='小柿智檢科技股份有限公司')
    .host
      h3 主辦單位
      a(href='https://buzzorange.com/techorange/', target='_blank'): img(src='@/assets/logo-white-techorange.png', alt='TechOrange 科技報橘')
  small
    span Copyright © {{ year }} 流線傳媒有限公司 All rights reserved.
    ul
      li: a(href='https://buzzorange.com/techorange/', target='_blank') TechOrange科技報橘
      li: a(href='https://buzzorange.com/', target='_blank') BuzzOrange報橘
      li: a(href='https://buzzorange.com/vidaorange/', target='_blank') VidaOrange生活報橘
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      year: new Date().getFullYear(),
    }
  },
}
</script>

<style lang="sass" scoped>
@import "~@/mixin.sass"

#v-footer
  +background(url(~@/assets/footer-bg.png), 1440px, repeat-x, center bottom)
  +padding-y(36px, 72px)
  background-color: black
  text-align: center
h3
  +font(28px, white, null, .05em, 500)
  margin-bottom: 48px
.logos
  margin-bottom: 200px
  .partners
    margin-bottom: 120px
    ul
      list-style-type: none
      li
        &:nth-of-type(1)
          img
            height: 28px
        &:nth-of-type(2)
          img
            height: 40px
        &:not(:last-of-type)
          margin-bottom: 40px
  .host
    img
      height: 32px
small
  +font(12px, white, 1.5, null, 300, center)
  +margin-x(auto)
  display: block
  transform-origin: 0 50%
  transform: scale(.9)
  width: calc(100% / 0.9)
  span
    +margin-x(.5em)
  ul
    list-style-type: none
    display: inline-flex
    li
      &:not(:last-child)::after
        +margin-x(.5em)
        content: '|'

@media (min-width: 1019px)
  #v-footer
    +padding-y(52px, 100px)
  .logos
    +margin-x(auto)
    +flex(space-around)
    margin-bottom: 288px
    .partners
      margin-bottom: 0
      ul
        +flex(center, center)
        li
          &:not(:last-of-type)
            margin-bottom: 0
            margin-right: 72px
  small
    transform: none
    width: 100%
</style>
