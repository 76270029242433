<template lang="pug">
#v-bar: .container
  label 解鎖精彩影片｜領取白皮書
  button(@click='$emit("show-form-popup")', type='button') 點我報名
</template>

<script>
export default {
}
</script>

<style lang="sass" scoped>
@import "~@/mixin.sass"

#v-bar
  +flex(null, center)
  +position(fixed, null, 0, 0, 0)
  background-color: rgba(black, .9)
  height: 60px
  .container
    +flex(center, center)
button
  +ifont(14px, white, null, .05em, 500)
  +size(96px, 32px)
  background-color: #74B71B
  border-radius: 100px
label
  +font(14px, white, null, .05em, 500)
  margin-right: 1em

@media (min-width: 1019px)
  #v-bar
    height: 80px
  label
    font-size: 20px
  button
    +size(144px, 48px)
    font-size: 20px
</style>
