<template lang="pug">
section#v-intro
  .container
    .introduction
      h2 4 道超殺關卡，揭曉 AI 瑕疵「簡」測力
      p 瑕疵檢測，是製造業公認最困難的魔王流程之一
      p 人工智慧、深度學習技術取代人為檢測，可以擊破生產效率的天花板嗎？
      p NVIDIA 和合作夥伴，今天就帶你進入 4 道高難度檢測關卡任務，用連擊 combo 把實測結果公開揭密！
      p 電子、一般製造、半導體廠導入 AI 有什麼落地差異？減了多少人力成本？還有什麼意想不到的成效 Bonus？
    v-divider PRESS START
    .trailer
      div
        h2 本次疾風破關，寶物攻略帶著走
        p 本次系列關卡更祭出神秘方形寶物 — NVIDIA 最新一款超級電腦 DGX STATION A100，號稱可隨插即用、簡單實現資料中心的 AI 運算效能，一舉殲滅工廠不該存在的瑕疵小怪物，真有這麼神？
      button.video(@click='$emit("show-video-popup", "https://www.youtube.com/embed/nLJAogNESm4")', type='button')
        img.preview(src='@/assets/intro-video.jpg', alt='台灣首開！超級電腦 AI 算力對決系列前導影片')
        img.play(src='@/assets/icon-play.png', alt='播放')
  .highlight: .container
    img(src='@/assets/intro-img.jpg', alt='DGX STATION A100')
    .box
      h2
        | 圖鑑：閃亮寶物
        |
        strong DGX STATION A100
        |
        | 有什麼特點？
      ul.features
        li 幾乎任何地點都能生存（你家、辦公室、實驗室都行），看到插座就喜歡黏上去
        li 伺服器等級，完全不需資料中心，卻可原地引爆資料中心的超級 AI 算力
        li 寶物 MIG 讓每位用戶可分配到高達 28 個 GPU，平行衝刺多個工作負載
      p 想了解更多？立即報名，以下 2 本攻略書就可下載到手！
      ul.whitepapers
        li 《AI 超級算力使用速成攻略書》
        li 《DL 深度學習高手製造攻略書》
</template>

<script>
import VDivider from '@/components/Divider.vue'

export default {
  components: {
    VDivider,
  },
}
</script>

<style lang="sass" scoped>
@import "~@/mixin.sass"

#v-intro
  padding-top: 60px
  background: linear-gradient(180deg, #1C4765 0%, black 100%)
.introduction
  margin-bottom: 60px
  h2
    +font(20px, #74B71B, 1.5, .05em, bold, center)
    margin-bottom: 20px
    &::after
      content: '👾🎮⚔️'
      display: block
      margin-top: 8px
  p
    +font(16px, white, 1.5, .05em, 300, justify)
    &:not(:last-of-type)
      margin-bottom: 20px
.trailer
  div
    h2
      +font(20px, #74B71B, null, .05em, bold)
      margin-bottom: 20px
    p
      +font(16px, white, 1.5, .05em, 300, justify)
      margin-bottom: 32px
  .video
    position: relative
    .preview
      border-radius: 8px
      width: 100%
    .play
      +position(absolute, 50%, null, null, 50%)
      transform: translate(-50%, -50%)
      width: 52px
.highlight
  +background(url(~@/assets/intro-bg.png), 720px, repeat, top center)
  +padding-y(120px, 72px)
  position: relative
  img
    +border(3px, #74B71B)
    +circle(150px)
    +position(absolute, 120px, null, null, 50%)
    transform: translate(-50%, -50%)
  .box
    +border(3px, #74B71B, 8px)
    background-color: rgba(white, .9)
    padding: 100px 20px 28px
    h2
      +font(18px, black, 1.5, .05em, bold, center)
      margin-bottom: 16px
      strong
        color: #74B71B
    .features
      list-style-type: none
      margin-bottom: 20px
      li
        +font(16px, black, 1.5, .05em, 300)
        display: flex
        &::before
          content: '◆'
          margin-right: .5em
    p
      +font(16px, black, 1.5, .05em)
      margin-bottom: 8px
    .whitepapers
      list-style-type: none
      li
        +font(16px, #74B71B, 1.5, .05em, bold)

@media (min-width: 1019px)
  #v-intro
    padding-top: 120px
  .introduction
    margin-bottom: 100px
    h2
      font-size: 24px
    p
      font-size: 20px
  .trailer
    +flex(null, center)
    div
      margin-right: 80px
      h2
        font-size: 24px
        margin-bottom: 28px
      p
        font-size: 20px
    .video
      .preview
        width: 480px
  .highlight
    background-size: 1200px
    +padding-y(288px, 128px)
    img
      +size(240px)
      border-width: 5px
      top: 288px
    .box
      +margin-x(auto)
      max-width: 1048px
      padding: 144px 120px 60px
      h2
        text-align: left
        font-size: 28px
      .features
        li
          font-size: 20px
      p
        font-size: 20px
      .whitepapers
        font-size: 20px
        li
          font-size: 20px
</style>
