<template lang="pug">
.v-divider: slot
</template>

<script>
export default {
}
</script>

<style lang="sass" scoped>
@import "~@/mixin.sass"

.v-divider
  +flex(center, center)
  +font(16px, #C0CDDD, null, 1px, bold)
  font-family: 'PT Mono'
  margin-bottom: 60px
  &::before,
  &::after
    +background(url(~@/assets/icon-arrows.png), 40px, no-repeat, left top)
    +size(40px, 16px)
    content: ''
    display: block
  &::before
    margin-right: 1em
  &::after
    margin-left: 1em

@media (min-width: 1019px)
  .v-divider
    margin-bottom: 80px
    font-size: 24px
    &::before,
    &::after
      +size(52px, 20px)
      background-size: 52px
</style>
