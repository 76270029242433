<template lang="pug">
section#v-reward: .container
  v-divider STAGE
  ul
    li
      label: span STAGE 01
      div
        small 攻擊力 UP
        h2 NVIDIA — AI 瑕疵檢測 X 製造業篇
        p 由 NVIDIA 資深資料科學家親自公開 AI 落地工廠的關鍵心法和超殺技術，及最新一代超級電腦助力製造業，可以實現什麼驚人的連擊效能？
      button(@click='showVideoPopup("https://www.youtube.com/embed/XsQEcjFa-no")', :class='$store.state.locked ? "locked" : "unlocked"', type='button')
        img.preview(src='@/assets/reward-video-1.jpg', alt='NVIDIA — AI 瑕疵檢測 X 製造業篇')
        .lock(v-if='$store.state.locked')
          img(src='@/assets/icon-lock.png', alt='待解鎖')
          span 立即解鎖
        .play(v-else)
          img(src='@/assets/icon-play.png', alt='播放')
    li
      label: span STAGE 02
      div
        small 暴擊力 UP
        h2 小柿智檢 — 傳統製造廠 X 機械手臂篇
        p 帶你看紡織、製鞋、食品加工業、電子廠怎麼讓 AI 神效檢測算力大爆發，破解不同材質、複雜形狀、2D/3D 外觀缺陷的魔王檢測任務
      button(@click='showVideoPopup("https://www.youtube.com/embed/It6oJaD6CXE")', :class='$store.state.locked ? "locked" : "unlocked"', type='button')
        img.preview(src='@/assets/reward-video-2.jpg', alt='小柿智檢 — 傳統製造廠 X 機械手臂篇')
        .lock(v-if='$store.state.locked')
          img(src='@/assets/icon-lock.png', alt='待解鎖')
          span 立即解鎖
        .play(v-else)
          img(src='@/assets/icon-play.png', alt='播放')
    li
      label: span STAGE 03
      div
        small 命中力 UP
        h2 NVIDIA x 小柿智檢 — 升級虛實整合工廠
        p 解析電子大廠和中小企業生態系，怎麼召喚數位轉型/數位雙生模組大絕，即使軟硬體人力、設備裝備屬性不足，也可攻陷特殊製造關卡
      button(@click='showVideoPopup("https://www.youtube.com/embed/oLGuI5knH98")', :class='$store.state.locked ? "locked" : "unlocked"', type='button')
        img.preview(src='@/assets/reward-video-3.jpg', alt='NVIDIA x 小柿智檢 — 升級虛實整合工廠')
        .lock(v-if='$store.state.locked')
          img(src='@/assets/icon-lock.png', alt='待解鎖')
          span 立即解鎖
        .play(v-else)
          img(src='@/assets/icon-play.png', alt='播放')
    li
      label: span STAGE 04
      div
        small 生命力 UP
        h2 智造 AI 算力終極戰 — DGX STATION A100
        p 想一次搞懂超級電腦的寶物使用守則？現在就下載《AI 超級算力使用速成攻略書》、《DL 深度學習高手製造攻略書》獲得 Max 最大備戰血量
      button(@click='openLink("https://drive.google.com/drive/folders/1OsOrMJHDncOTw_OVbekOY1c3e9sf-wyl?usp=sharing")', :class='$store.state.locked ? "locked" : "unlocked"', type='button')
        img.preview(src='@/assets/reward-whitepaper.jpg', alt='AI 超級算力使用速成攻略書 DL 深度學習高手製造攻略書')
        .lock(v-if='$store.state.locked')
          img(src='@/assets/icon-lock.png', alt='待解鎖')
          span 立即解鎖
        .link(v-else)
          span 由此領取
          img(src='@/assets/icon-external.png', alt='外部連結')
</template>

<script>
import VDivider from '@/components/Divider.vue'

export default {
  components: {
    VDivider,
  },
  methods: {
    showVideoPopup(videoUrl) {
      if(!this.$store.state.locked) {
        this.$emit('show-video-popup', videoUrl)
      } else {
        this.$emit('show-form-popup')
      }
    },
    openLink(link) {
      if(!this.$store.state.locked) {
        window.open(link)
      } else {
        this.$emit('show-form-popup')
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@import "~@/mixin.sass"

#v-reward
  +padding-y(60px, 72px)
  background: linear-gradient(180deg, black 0%, #1C4765 100%)
ul
  list-style-type: none
  li
    +border(1px, #74B71B, 8px)
    background-color: white
    overflow: hidden
    &:not(:last-of-type)
      margin-bottom: 20px
    > label
      background-color: #74B71B
      display: block
      height: 36px
      padding-left: 12px
      padding-top: 8px
      span
        +font(16px, white, null, .05em, bold)
        font-family: 'PT Mono'
    > div
      padding: 16px 12px 0
      small
        +font(14px, #74B71B, null, .05em, 300)
        display: block
        margin-bottom: 8px
      h2
        +font(18px, black, 1.5, .05em, bold)
        margin-bottom: 4px
      p
        +font(16px, black, 1.5, .05em, null, justify)
        margin-bottom: 32px
    button
      border-radius: 8px 8px 0 0
      display: block
      overflow: hidden
      position: relative
      .preview
        width: 100%
      .lock
        +flex(center, center, column)
        +position(absolute, 0, 0, 0, 0)
        background-color: rgba(black, .6)
        border-radius: 8px 8px 0 0
        img
          margin-bottom: 12px
          width: 52px
        span
          +font(16px, white, null, .05em)
          display: block
      .play
        +flex(center, center)
        img
          +position(absolute, 50%, null, null, 50%)
          transform: translate(-50%, -50%)
          width: 52px
      .link
        +iflex(center, center)
        +ifont(16px, white, null, .05em, bold, center)
        +position(absolute, 50%, null, null, 50%)
        +size(140px, 44px)
        background-color: rgba(black, .8)
        border-radius: 100px
        transform: translate(-50%, -50%)
        &:hover
          text-decoration: none
        img
          width: 1.2em
          margin-left: .5em

@media (min-width: 1019px)
  #v-reward
    +padding-y(80px, 120px)
  ul
    li
      display: grid
      grid-template-columns: 64px 1fr 480px
      &:not(:last-of-type)
        margin-bottom: 40px
      > label
        height: 100%
        padding-top: 16px
        span
          display: block
          font-size: 28px
          transform: rotate(-90deg) translateX(-120px)
          white-space: nowrap
      > div
        padding: 36px 56px 0 28px
        h2
          font-size: 22px
        p
          font-size: 18px
        small
          font-size: 16px
          text-align: left
      button
        border-radius: 8px 0 0 8px
</style>
