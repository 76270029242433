<template lang="pug">
form#v-form(@submit.prevent='submit')
  .dimmer(@click='$emit("hide-form-popup")')
  transition(name='popup', appear): .popup
    .inputs
      .field
        label 您的姓名
        input(v-model='registrant.name', :disabled='status !== "pending"', name='name', type='text', required)
      .field
        label 您的信箱
        input(v-model='registrant.email', :disabled='status !== "pending"', name='email', type='email', required)
      .field
        label 您的手機
        input(v-model='registrant.mobile_phone', :disabled='status !== "pending"', name='mobile_phone', type='tel', required)
      .field
        label 您的公司
        input(v-model='registrant.company_name', :disabled='status !== "pending"', name='company_name', type='text', required)
    .dropdowns
      select(v-model='registrant.industry', :disabled='status !== "pending"', name='industry', required)
        option(value='', disabled, selected) 產業類別
        option(value='高科技製造') 高科技製造
        option(value='資訊軟體服務') 資訊軟體服務
        option(value='資訊硬體製造') 資訊硬體製造
        option(value='資訊安全') 資訊安全
        option(value='資訊系統整合') 資訊系統整合
        option(value='網路通訊') 網路通訊
        option(value='電信服務') 電信服務
        option(value='金融保險') 金融保險
        option(value='醫療生技') 醫療生技
        option(value='能源產業') 能源產業
        option(value='零售電商') 零售電商
        option(value='傳產製造') 傳產製造
        option(value='政府機關') 政府機關
        option(value='媒體行銷') 媒體行銷
        option(value='公協會/財團法人') 公協會/財團法人
        option(value='教育機構') 教育機構
        option(value='服務業') 服務業
        option(value='學生') 學生
        option(value='其他') 其他
      select(v-model='registrant.job_title', :disabled='status !== "pending"', name='job_title', required)
        option(value='', disabled, selected) 職務名稱
        option(value='執行長（CEO）') 執行長（CEO）
        option(value='資訊長（CIO）') 資訊長（CIO）
        option(value='技術長（CTO）') 技術長（CTO）
        option(value='顧問') 顧問
        option(value='資料科學家') 資料科學家
        option(value='開發人員/程式設計師') 開發人員/程式設計師
        option(value='工程師') 工程師
        option(value='剪輯師') 剪輯師
        option(value='平面設計師/動畫師') 平面設計師/動畫師
        option(value='工業設計師/產品設計師') 工業設計師/產品設計師
        option(value='IT 管理員/系統管理員') IT 管理員/系統管理員
        option(value='IT 經理/IT 總監/IT 副總裁') IT 經理/IT 總監/IT 副總裁
        option(value='行銷經理') 行銷經理
        option(value='教授/指導教授') 教授/指導教授
        option(value='採購經理') 採購經理
        option(value='研究員') 研究員
        option(value='銷售工程師') 銷售工程師
        option(value='銷售代表/銷售經理') 銷售代表/銷售經理
        option(value='學生/研究生') 學生/研究生
        option(value='其他') 其他
    .agreement
      label
        input(v-model='registrant.is_subscribe_to_nvidia_corporate_news', :disabled='status !== "pending"', name='is_subscribe_to_nvidia_corporate_news', type='checkbox')
        p 我想收到 NVIDIA 的最新企業新聞，產品活動資訊，公告和更多信息。我可以隨時取消訂閱。（可選）
      label
        input(v-model='registrant.is_subscribe_to_nvidia_developer_information', :disabled='status !== "pending"', name='is_subscribe_to_nvidia_developer_information', type='checkbox')
        p 我想收到 NVIDIA 的最新開發人員新聞，公告和更多信息。 我可以隨時取消訂閱。（可選）
      label
        input(v-model='registrant.privacy_confirmation', :disabled='status !== "pending"', type='checkbox', name='privacy_confirmation', required)
        p
          | *我已詳閱並同意
          a(href='https://www.nvidia.com/zh-tw/privacy-center/', target='_blank') 「NVIDIA 的隱私權政策」
          | 及
          a(href='/主辦單位蒐集個資聲明.pdf', target='_blank') 「主辦單位蒐集個資聲明」
          | 。（必填）
    .submission
      button(:disabled='status !== "pending"', type='submit') {{ button }}
</template>

<script>
import { __, dissoc, reject, isEmpty, mergeRight, pipe } from 'ramda'

export default {
  data() {
    return {
      status: 'pending', // pending, submitting, submitted
      registrant: {
        name: '',
        email: '',
        mobile_phone: '',
        company_name: '',
        industry: '',
        job_title: '',
        is_subscribe_to_nvidia_corporate_news: false,
        is_subscribe_to_nvidia_developer_information: false,
        privacy_confirmation: false,
      },
    }
  },
  computed: {
    button() {
      if(this.status === 'submitting') {
        return '解鎖中...'
      } else if(this.status === 'submitted') {
        return '已解鎖'
      } else {
        return '提交資料'
      }
    },
  },
  methods: {
    submit() {
      this.status = 'submitting'

      const mergeUtm = mergeRight(__, this.$store.state.utm)
      const rejectEmpty = reject(isEmpty)
      const registrant = pipe(mergeUtm, rejectEmpty)(this.registrant)

      this.$store.dispatch('postRegistrant', registrant).then(res => {
        setTimeout(() => {
          this.status = 'submitted'
          this.$scrollTo('#v-reward')
          this.$emit('hide-form-popup')
          this.$store.commit('unlock')
          localStorage.setItem('locked', 'false')
        }, 1000)
      }).catch(err => {
        if(err.response.data.detail) {
          alert(`您的資料提交失敗\n${err.response.data.detail}`)
        } else {
          alert('系統忙碌中，請稍後再試')
        }
        this.status = 'pending'
      })
    },
  },
}
</script>

<style lang="sass" scoped>
@import "~@/mixin.sass"

.popup-enter-active, .popup-leave-active
  transition: opacity .2s .2s, transform .2s .2s
.popup-enter, .popup-leave-to
  opacity: 0
  transform: translateY(-20px)

#v-form
  +flex(center, center)
  +position(fixed, 0, 0, 0, 0)
  z-index: 99
.dimmer
  +position(absolute, 0, 0, 0, 0)
  background-color: rgba(black, .4)
.popup
  background-color: white
  border-radius: 12px
  padding: 20px
  position: relative
  width: calc(100% - 60px)
.inputs
  display: grid
  grid-gap: 10px
  margin-bottom: 16px
  .field
    position: relative
    input[type='email'],
    input[type='tel'],
    input[type='text']
      +size(100%, 32px)
      background-color: transparent
      border-bottom: 1px solid #B4B4B4
      color: black
      padding-left: 5em
      transition: border-color .2s, opacity .2s
      &:-webkit-autofill
        transition: background-color 999999s 999999s, color 999999s 999999s
      &:focus
        border-color: #74B71B
      &:disabled
        opacity: .6
    label
      +font(14px, #71717A, null, .05em)
      +position(absolute, 50%, null, null, .5em)
      pointer-events: none
      transform: translateY(-50%)
.dropdowns
  display: grid
  grid-gap: 16px
  grid-template-columns: repeat(2, 1fr)
  margin-bottom: 16px
  select
    +background(url(~@/assets/icon-dropdown.png), 10px, no-repeat, right .5em center)
    +border(1px, #D6D6D6, 3px)
    +font(14px, black, null, .05em)
    +size(100%, 32px)
    background-color: white
    transition: opacity .2s
    &:invalid
      color: #727272
    &:disabled
      opacity: .6
.agreement
  margin-bottom: 16px
  label
    +flex(null, flex-start)
    margin-bottom: 8px
    &:last-child
      p
        font-weight: bold
      a
        color: #74B71B
    input[type='checkbox']
      flex: none
      margin-top: 4px
    p
      +font(14px, #8F8F8F, 1.5)
.submission
  text-align: center
  button
    +ifont(16px, white, null, 1.6px)
    +size(160px, 40px)
    background-color: #74B71B
    border-radius: 100px
    transition: opacity .2s
    &:disabled
      opacity: .6

@media (min-width: 1019px)
  .popup
    max-width: 480px
    padding: 48px 56px
  .agreement
    margin-bottom: 28px
</style>
