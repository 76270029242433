<template lang="pug">
section#v-speaker: .container
  v-divider STAFF
  ul
    li
      figure: img(src='@/assets/speaker-portrait-1.jpg', alt='劉冠良 Andrew Liu')
      img.logo(src='@/assets/logo-nvidia.png', alt='NVIDIA 香港商輝達香港控股有限公司台灣分公司')
      .name 劉冠良 Andrew Liu
      .title NVIDIA 資深資料科學家
      hr
      strong 熱衷於把 AI 算法用在現實世界問題
      p NVIDIA 資深資料科學家，協助客戶構建基於 NVIDIA 技術的創新解決方案，研究興趣是將機器學習算法應用於現實世界的問題。過去是富士康的機器學習工程師，他領導了分析團隊，開發各種以製造流程為中心的預測建模項目，熟悉缺陷檢查和預測性維護。
    li
      figure: img(src='@/assets/speaker-portrait-2.jpg', alt='洪沛駿 Gary Hung')
      img.logo(src='@/assets/logo-kakix.png', alt='小柿智檢科技股份有限公司')
      .name 洪沛駿 Gary Hung
      .title 小柿智檢 執行長
      hr
      strong 擁有 10+ 年工業 AI 技術開發和管理經驗
      p 小柿智檢 CEO，成功大學碩士，擁有 10+ 年工業人工智能技術開發及管理經驗。經歷為前富士康集團之人工智能部部長、前北京比特大陸之算法部負責人、前美的集團之集團人工智能專家、光谷 3551 人才計劃長期創新人才，及黃鶴英才計劃產業領軍人物。
    li
      figure: img(src='@/assets/speaker-portrait-3.jpg', alt='王又冉 Yolanda Wang')
      img.logo(src='@/assets/logo-techorange.png', alt='TechOrange 科技報橘')
      .name 王又冉 Yolanda Wang
      .title 科技報橘 特約節目主持人
      hr
      strong 擅長把超艱深技術轉化為平易近人的語言
      p 媒體資歷 12 年，主跑科技線，待過平面、網路、電視，也曾任壹電視主播、主持人，熱衷挖掘一切新鮮人事物，擅長將艱深的技術、議題轉化為容易理解的語言，現在悠遊各媒體打醬油，擔任特約主持人，也主持記者會、活動、寫稿。
</template>

<script>
import VDivider from '@/components/Divider.vue'

export default {
  components: {
    VDivider,
  },
}
</script>

<style lang="sass" scoped>
@import "~@/mixin.sass"

#v-speaker
  +padding-y(60px, 72px)
  background: linear-gradient(180deg, #1C4765 0%, black 100%)
ul
  list-style-type: none
  li
    background-color: white
    border-radius: 8px
    padding: 40px 20px 28px
    &:not(:last-of-type)
      margin-bottom: 32px
    &:nth-of-type(1)
      .logo
        height: 28px
    &:nth-of-type(2)
      .logo
        height: 40px
    &:nth-of-type(3)
      .logo
        height: 32px
    figure
      +margin-x(auto)
      display: block
      margin-bottom: 40px
      position: relative
      text-align: center
      &::before
        +background(url(~@/assets/speaker-frame.png))
        +position(absolute, 50%, 0, 0, 50%)
        +size(180px)
        content: ''
        display: block
        pointer-events: none
        transform: translate(-50%, -50%)
      img
        +circle(144px)
    .logo
      display: block
      margin-bottom: 20px
      height: 30px
    .name
      +font(20px, black, null, .05em, 500)
      margin-bottom: 8px
    .title
      +font(14px, black, null, .05em, 300)
    hr
      +margin-y(28px, 20px)
      background-color: #74B71B
      border: none
      height: 1px
    strong
      +font(16px, black, 1.5, .05em, 500)
      margin-bottom: 12px
      display: block
    p
      +font(14px, black, 1.5, .05em, 300, justify)

@media (min-width: 1019px)
  #v-speaker
    +padding-y(80px, 100px)
    li
      +margin-x(auto)
      max-width: 640px
      padding: 52px
      figure
        +margin-x(12px, 80px)
        float: left
      .logo
        margin-top: 28px
      hr
        clear: left
</style>
