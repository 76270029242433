<template lang="pug">
#v-app
  v-main
  v-intro(@show-video-popup='showVideoPopup')
  v-reward(@show-form-popup='formPopup.shown = true', @show-video-popup='showVideoPopup')
  v-extra
  v-speaker
  v-footer
  transition(name='slide'): v-bar(v-if='$store.state.locked', @show-form-popup='formPopup.shown = true')
  transition(name='fade'): v-video(v-if='videoPopup.shown', @hide-video-popup='videoPopup.shown = false', :video-url='videoPopup.videoUrl')
  transition(name='fade'): v-form(v-if='formPopup.shown', @hide-form-popup='formPopup.shown = false')
</template>

<script>
import { parse as parseQuery } from 'query-string'
import VMain from '@/components/Main.vue'
import VIntro from '@/components/Intro.vue'
import VReward from '@/components/Reward.vue'
import VExtra from '@/components/Extra.vue'
import VSpeaker from '@/components/Speaker.vue'
import VFooter from '@/components/Footer.vue'
import VBar from '@/components/Bar.vue'
import VVideo from '@/components/Video.vue'
import VForm from '@/components/Form.vue'

export default {
  name: 'App',
  components: {
    VMain,
    VIntro,
    VReward,
    VExtra,
    VSpeaker,
    VFooter,
    VBar,
    VVideo,
    VForm,
  },
  data() {
    return {
      videoPopup: {
        videoUrl: '',
        shown: false,
      },
      formPopup: {
        shown: false,
      },
    }
  },
  methods: {
    showVideoPopup(videoUrl) {
      this.videoPopup = {
        videoUrl,
        shown: true,
      }
    },
  },
  created() {
    document.cookie=`XSRF-TOKEN=${Math.random().toString(36).substr(2)}`
    $(window).resize(() => {
      const mobile = $(window).width() < 1020
      if(this.$store.state.mobile !== mobile) {
        this.$store.commit('setMobile', mobile)
      }
    }).resize()
    this.$store.commit('saveUtm', parseQuery(location.search))

    if(localStorage.getItem('locked') === 'false') {
      this.$store.commit('unlock')
    }
  },
}
</script>

<style lang="sass" scoped>
@import "~@/mixin.sass"

.slide-leave-active
  transition: transform .4s
.slide-leave-to
  transform: translateY(100%)
.fade-enter-active, .fade-leave-active
  transition: opacity .2s
.fade-enter, .fade-leave-to
  opacity: 0

#v-app
  min-width: 360px
</style>
