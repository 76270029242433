<template lang="pug">
.v-video
  .dimmer(@click='$emit("hide-video-popup")')
  span 請稍候...
  iframe(:src='videoUrl', width='960', height='540', title='YouTube video player', frameborder='0', allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture', allowfullscreen)
</template>

<script>
export default {
  props: {
    videoUrl: {
      type: String,
      required: true,
    },
  }
}
</script>

<style lang="sass" scoped>
@import "~@/mixin.sass"

.v-video
  +flex(center, center)
  +position(fixed, 0, 0, 0, 0)
  z-index: 99
.dimmer
  +position(absolute, 0, 0, 0, 0)
  background-color: rgba(black, .9)
span
  +font(16px, white, null, .05em)
  +position(absolute, 50%, null, null, 50%)
  transform: translate(-50%, -50%)
iframe
  width: 100vw
  height: calc(100vw / 560 * 315)
  max-width: 560px
  max-height: 315px
  position: relative

@media (min-width: 1019px)
  span
    font-size: 18px
  iframe
    max-width: 960px
    max-height: 540px
</style>
