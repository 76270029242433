<template lang="pug">
main#v-main: .container
  .logo
    img(src='@/assets/logo-techorange.png', alt='TechOrange 科技報橘')
    img(src='@/assets/logo-nvidia.png', alt='NVIDIA 香港商輝達香港控股有限公司台灣分公司')
  img.kv(src='@/assets/main-kv.png', alt='台灣首開！超級電腦 AI 算力對決系列')
  h1
    strong 台灣首開！
    | 超級電腦 AI 算力對決系列
  h2 NVIDIA 帶你破解工廠檢測 4 道超殺關卡
</template>

<script>
export default {
}
</script>

<style lang="sass" scoped>
@import "~@/mixin.sass"

#v-main
  +background(url(~@/assets/main-bg.jpg))
  +padding-y(28px, 40px)
.logo
  +flex(null, center)
  margin-bottom: 20px
  img
    height: 20px
    &:not(:last-child)
      margin-right: 24px
.kv
  +margin-x(auto)
  display: block
  max-width: 400px
  width: 100%
h1
  +font(24px, black, null, .05em, bold)
  margin-bottom: 20px
  strong
    +font(30px, #74B71B, null, .05em, bold)
    margin-bottom: 12px
    display: block
h2
  +font(20px, black, 1.3, .05em, 500)

@media (min-width: 1019px)
  #v-main
    +padding-y(60px, 140px)
  .logo
    img
      height: 32px
      &:not(:last-child)
        margin-right: 32px
  .kv
    float: right
    margin-left: 40px
    max-width: 560px
    width: calc(100% - 480px)
  h1
    +font(52px, null, 1.3)
    margin-bottom: 36px
    strong
      font-size: 60px
      margin-top: 144px
  h2
    font-size: 28px
</style>
